<template>
  <div class="container mx-auto booking-request" style="z-index: 400">
    <div class="flex overflow-hidden bg-white sp-profile-wrap">
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto">
        <div class="pt-6 px-4">
          <h1 class="pb-3">Service booking request</h1>
          <div class="w-full grid grid-cols-12 gap-4 pb-6">
            <div class="col-span-4 left">
              <div class="w-full bg-white rounded-xl sahdow-lg overflow-hidden flex flex-col justify-start items-start item drop-shadow-md">
                <div class="w-full">
                  <img :src="image" alt="photo" class="object-center object-cover h-auto w-full" />
                </div>
                <div class="flex justify-center items-center px-3">
                  <rating-bar :rating="consultant == null ? null : 0" />
                </div>
                <div class="py-8 sm:py-6 px-3">
                  <p class="name text-xl mb-2 font-normal">{{ consultant == null ? null : consultant.name }}</p>
                  <p class="job-title text-base font-normal">{{ consultant == null ? null : consultant.tagline }}</p>
                  <p class="desc pt-2">{{ consultant == null ? null : consultant.description }}</p>
                </div>
              </div>
            </div>
            <div class="col-span-4 middle">
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 availability mt-2">
                <h2 class="pb-3">Availability</h2>
                <calendar :attributes="calanderProps" />
              </div>
            </div>

            <div class="col-span-4 right">
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 availability mt-2">
                <h2 class="pb-2">Select requirements</h2>
                <div class="w-full">
                  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="mr-2 mb-2" />
                  <label for="vehicle1">Request for quotation</label><br />
                  <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" class="mr-2 mb-2" />
                  <label for="vehicle2">Request for quotation</label><br />
                  <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" class="mr-2 mb-2" />
                  <label for="vehicle3">Request for quotation</label><br /><br />
                  <textarea class="w-full border rounded-3xl p-3" rows="5" placeholder="Special requirements"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full grid grid-cols-12 gap-4 pb-6">
            <div class="col-span-4 left">
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 meeting-sp">
                <h2 class="mb-2">Special info</h2>
                <ul class="list-disc">
                  <li class="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tristique risus ante.</li>
                  <li class="pb-3">Duis non eros mollis, pellentesque magna at, pharetra enim. .</li>
                  <li class="pb-3">Suspendisse potenti. Sed imperdiet purus nunc, at blandit dolor iaculis et.</li>
                  <li class="pb-3">Pellentesque magna mauris, tincidunt sed sapien at, pharetra fermentum neque.</li>
                  <li class="pb-3">Fusce fringilla pretium porttitor.</li>
                </ul>
              </div>
            </div>
            <div class="col-span-8 right">
              <textarea class="w-full shadow" rows="12"></textarea>
              <button
                @click.prevent="onClickRequestConsultation"
                :class="{ 'cursor-not-allowed': this.user == null }"
                class="w-full bg-blue-500 hover:bg-blue-700 text-white rounded-md font-medium py-4 px-4 mt-6">
                Request a consultation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RatingBar from '@/components/RatingBar.vue';
import consultant_service from '@/services/consultant_service';

export default {
  name: 'consultant_booking',
  components: {
    'rating-bar': RatingBar,
  },
  data() {
    return {
      consultant: null,
      calanderProps: [
        { dates: '2022-03-10', highlight: true },
        { dates: '2022-03-12', highlight: true },
        { dates: '2022-03-16', highlight: true },
      ],
      image: null,
    };
  },
  computed: {
    user() {
      return this.$session.get('user');
    },
  },
  created() {
    consultant_service
      .getConsultant(this.$route.params.id)
      .then(({ data }) => {
        this.consultant = data.consultant;
        return this.$s3.getDpUrl(this.consultant.id);
      })
      .then((url) => (this.image = url))
      .catch(({ response }) => this.$msg.error({ title: 'Unable to load image url', message: response.data.error }));
  },
  methods: {
    onClickRequestConsultation() {
      consultant_service
        .bookConsultant(this.user.id, this.consultant.id, '2022-04-04T14:00:00+05:30')
        .then(() => this.$msg.success({ title: 'Booking Placed', message: 'Your booking has been placed successfully' }))
        .catch(({ response }) => this.$msg.error({ title: 'Unable to place booking', message: response.data.error }));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
